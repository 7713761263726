import React, { useEffect, useState } from 'react';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { EmptyDoctor } from 'assets';
import styles from './EmptyStub.module.scss';

export const EmptyStub = () => {
  return (
    <div className={styles.container}>
      <EmptyDoctor />
      <div className={styles.text}>
        Unfortunately, no results were found for your request!
      </div>
    </div>
  );
};
