// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomButton_container__uERKq {
  width: 100%;
  height: 38px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  margin: 0 auto;
  background: linear-gradient(to right, #478ef0, #00b8ea);
}

.CustomButton_iconContainer__OAgLF {
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomButton_dark__yy6ya {
  background: #0149ad;
  background-color: #0149ad;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomButton/CustomButton.module.scss","webpack://./src/common/styles/variables.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,cCPO;EDQP,eAAA;EACA,cAAA;EAEA,uDAAA;AAHF;;AASA;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AANF;;AASA;EACE,mBCvBO;EDwBP,yBCxBO;ADkBT","sourcesContent":["@import 'src/common/styles/mixins';\n@import 'src/common/styles/variables';\n\n.container {\n  width: 100%;\n  height: 38px;\n  border-radius: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 14px;\n  color: $color5;\n  cursor: pointer;\n  margin: 0 auto;\n  //margin-top: 10px;\n  background: linear-gradient(to right, #478ef0, #00b8ea);\n  //position: absolute;\n  //bottom: 20px;\n  //margin-left: 5%;\n}\n\n.iconContainer {\n  padding-right: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.dark {\n  background: $color7;\n  background-color: $color7;\n}\n","$color1: #d8d8d8;\n$color2: #3c3c60;\n$color3: #808080;\n$color4: #00b8ea;\n$color5: #ffffff;\n$color6: #bebebe;\n$color7: #0149ad;\n$color8: #dbe0e4;\n$color9: #c5c4cf;\n\n$textColor-l1: #0a142f;\n$textColor-l2: #666;\n$textColor-l3: #040404;\n\n$backgroundColor-l1: #ffffff;\n$backgroundColor-l2: #111;\n$backgroundColor-l3: #d2d2d2;\n\n$lineColor-l1: #9fb4cd;\n$lineColor-l2: #333;\n\n$errorColor-l1: #ff4b55;\n$successColor: #34a853;\n$linkColor: #4f72ee;\n$iconColor: rgb(132, 138, 155);\n\n// font Family\n$font-l1: 'Poppins';\n\n// font sizes\n$fontSize-xs: 0.875rem;\n$fontSize-s: 1rem;\n$fontSize-m: 1.25rem;\n$fontSize-l: 2.25rem;\n$fontSize-xl: 3.25rem;\n\n// line heights\n$lineHeight-s: 1rem;\n$lineHeight-m: 1.5rem;\n$lineHeight-l: 2rem;\n\n// font weights\n$fontWeight-s: 400;\n$fontWeight-m: 500;\n$fontWeight-l: 600;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CustomButton_container__uERKq`,
	"iconContainer": `CustomButton_iconContainer__OAgLF`,
	"dark": `CustomButton_dark__yy6ya`
};
export default ___CSS_LOADER_EXPORT___;
