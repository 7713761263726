import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8.99721"
      cy="6.91225"
      r="2.97143"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
    />
    <path
      d="M13.363 15.2453C13.363 12.6154 11.4087 10.4834 8.99789 10.4834C6.58712 10.4834 4.63281 12.6154 4.63281 15.2453"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
    />
    <circle
      cx="8.9974"
      cy="8.49935"
      r="7.73333"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
