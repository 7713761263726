// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input {
  outline: none;
  width: 90%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.custom-input-container {
  width: 100%;
  border: 1px solid #d7d7d7;
  margin: 0 auto;
  border-radius: 10px;
  height: 34px;
  display: flex;
  padding: 2px;
}

.input-title {
  width: 94%;
  margin: 0 auto;
  margin-bottom: 3px;
}

.custom-input-icon-container {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomInput/CustomInput.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,UAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,WAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AAFF;;AAKA;EACE,UAAA;EACA,cAAA;EACA,kBAAA;AAFF;;AAKA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAFF","sourcesContent":["@import 'src/common/styles/mixins';\n@import 'src/common/styles/variables';\n\n.custom-input {\n  outline: none;\n  width: 90%;\n  margin: 0 auto;\n  padding-left: 15px;\n  padding-right: 15px;\n  border: none;\n}\n\n.input-container {\n  display: flex;\n  flex-direction: column;\n  margin-top: 10px;\n}\n\n.custom-input-container {\n  width: 100%;\n  border: 1px solid #d7d7d7;\n  margin: 0 auto;\n  border-radius: 10px;\n  height: 34px;\n  display: flex;\n  padding: 2px;\n}\n\n.input-title {\n  width: 94%;\n  margin: 0 auto;\n  margin-bottom: 3px;\n}\n\n.custom-input-icon-container {\n  width: 10%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
