// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Menu_container__p9Ypa {
  width: 256px;
  border-right: 1px solid #d8d8d8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.Menu_logo__6lVPe {
  width: 74px;
  height: 74px;
  margin: 0 auto;
  margin-top: 37px;
}

.Menu_buttonContainer__YhoCA {
  width: 90%;
  margin-left: 5%;
  bottom: 20px;
  position: absolute;
}

.Menu_itemsContainer__08smG {
  margin-top: 100px;
  position: relative;
  display: block;
}

@media only screen and (max-width: 1250px) {
  .Menu_container__p9Ypa {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.module.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,+BAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;AAFF;;AAKA;EACE,UAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AAFF;;AAKA;EACE,iBAAA;EACA,kBAAA;EACA,cAAA;AAFF;;AAKA;EACE;IACE,aAAA;EAFF;AACF","sourcesContent":["@import 'src/common/styles/variables';\n@import 'src/common/styles/mixins';\n\n.container {\n  width: 256px;\n  border-right: 1px solid $color1;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n\n.logo {\n  width: 74px;\n  height: 74px;\n  margin: 0 auto;\n  margin-top: 37px;\n}\n\n.buttonContainer {\n  width: 90%;\n  margin-left: 5%;\n  bottom: 20px;\n  position: absolute;\n}\n\n.itemsContainer {\n  margin-top: 100px;\n  position: relative;\n  display: block;\n}\n\n@media only screen and (max-width: 1250px) {\n  .container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Menu_container__p9Ypa`,
	"logo": `Menu_logo__6lVPe`,
	"buttonContainer": `Menu_buttonContainer__YhoCA`,
	"itemsContainer": `Menu_itemsContainer__08smG`
};
export default ___CSS_LOADER_EXPORT___;
