import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 17.4998V6.6665C2.5 5.28579 3.61929 4.1665 5 4.1665H13.3333C14.714 4.1665 15.8333 5.28579 15.8333 6.6665V11.6665C15.8333 13.0472 14.714 14.1665 13.3333 14.1665H5.83333L2.5 17.4998"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.8335 7.50016H12.5002"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.8335 10.8332H10.8335"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
