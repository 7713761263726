import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LsKeys } from 'enums';
import {
  getDoctor,
  getDoctorFinances,
  getDoctors,
  getDoctorVisits,
} from 'state/thunks/doctorThunk/doctorThunk';
import { editFinance } from 'state/thunks/financeThunk/financeThunk';
import {
  createNotification,
  getNotifications,
} from 'state/thunks/notificationThunk/notificationThunk';
import {
  editUser,
  getPatientVisits,
  getUser,
  getUsers,
} from 'state/thunks/userThunk/userThunk';

const initialState = {
  doctors: null,
  doctor: null,
  count: null,
  lastPage: null,
  page: null,
  user: null,
  isLoading: false,
  visits: [],
  finances: [],
};

const slice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    setLogOut: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder
      .addCase(getDoctorVisits.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get doctor success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.visits = action.payload;
      })
      .addCase(getDoctorFinances.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get doctor finances success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.finances = action.payload;
      })
      .addCase(getDoctor.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get user success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.doctor = action.payload;
      })
      .addCase(getDoctors.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get doctor success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.doctors = action.payload;
        state.count = action.payload.count;
        state.lastPage = action.payload.lastPage;
        state.page = action.payload.page;
      })
      .addCase(editUser.pending, (state, action: PayloadAction<any>) => {
        state.isLoading = true;
      })
      .addCase(editUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(editUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      })
      .addCase(getUsers.rejected, (state, action: PayloadAction<any>) => {
        //state.error = action.payload;
        //state.isLoading = false;
      });
  },
});

export const doctorActions = slice.actions;

export const doctorReducer = slice.reducer;
