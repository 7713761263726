import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.25"
      y="0.25"
      width="37.5"
      height="37.5"
      rx="7.75"
      stroke="#D8D8D8"
      stroke-width="0.5"
    />
    <path
      d="M20.5 27H13C11.8954 27 11 26.1046 11 25V13C11 11.8954 11.8954 11 13 11H25C26.1046 11 27 11.8954 27 13V21.5"
      stroke="#3C3C60"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11 16H27"
      stroke="#3C3C60"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="25.9997"
      cy="26.0002"
      r="1.16667"
      stroke="#3C3C60"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.8337 25.9998C30.2779 28.7223 28.3337 30.0832 26.0003 30.0832C23.667 30.0832 21.7227 28.7223 20.167 25.9998C21.7227 23.2774 23.667 21.9165 26.0003 21.9165C28.3337 21.9165 30.2779 23.2774 31.8337 25.9998"
      stroke="#3C3C60"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
