import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createArticleRequest,
  getArticleRequest,
  getContactRequest,
  updateContactRequest,
} from 'api';

export const getContact = createAsyncThunk(
  'contact/get',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getContactRequest();

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const updateContact = createAsyncThunk(
  'contact/update',
  async (data: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await updateContactRequest(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
    }
  },
);

export const contactUsThunk = {
  getContact,
  updateContact,
};
