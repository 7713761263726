import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.0396"
      cy="8.32706"
      r="2.99786"
      stroke="#3C3C60"
      stroke-width="0.820018"
    />
    <path
      d="M14.2052 16.2787C14.2052 13.7692 12.3404 11.7349 10.04 11.7349C7.73969 11.7349 5.87488 13.7692 5.87488 16.2787"
      stroke="#3C3C60"
      stroke-width="0.820018"
    />
    <circle
      cx="10.0395"
      cy="9.84183"
      r="7.54168"
      stroke="#3C3C60"
      stroke-width="0.820018"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
