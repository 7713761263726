import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TableView, TableViewOpen } from 'assets';
import { Checkbox } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';

const options = [
  'None',
  'Atria',
  'Callisto',
  'Dione',
  'Ganymede',
  'Hangouts Call',
  'Luna',
  'Oberon',
  'Phobos',
  'Pyxis',
  'Sedna',
  'Titania',
  'Triton',
  'Umbriel',
];

const ITEM_HEIGHT = 48;

export const CustomDropdown = ({ columns, handleColumnChange }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {open ? <TableViewOpen /> : <TableView />}
      </IconButton>
      <Menu
        id="long-menu"
        // MenuListProps={{
        //   'aria-labelledby': 'long-button',
        // }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '30ch',
            padding: '5px',
          },
        }}
      >
        {columns.map((column: any) => (
          <MenuItem value={column.title}>
            <ListItemText primary={column.name} />
            <Checkbox
              onChange={e => {
                handleColumnChange(column.name);
              }}
              checked={column.isSelect}
              style={{ color: '#00B8EA' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
