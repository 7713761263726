export const routes = {
  AUTH: {
    LOG_IN: '/login',
    RESET_PASSWORD: '/reset-password',
    CHANGE_PASSWORD: '/change-password',
    SIGN_UP: '/sign-up',
  },
  MAIN: {
    INIT: '/dashboard',
    USERS: '/users',
    DOCTORS: '/doctors',
    USER: '/user',
    DOCTOR: '/doctor',
    FINANCES: '/finances',
    ARTICLES: '/articles',
    COMMISSION: '/commission',
    NOTIFICATION: '/notification',
    DASHBOARD: '/dashboard',
  },
} as const;
