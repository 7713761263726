// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.ModalLayout_container__3SBgU {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center§;
  z-index: 999;
}

.ModalLayout_modal__SK3jt {
  position: absolute; /* Абсолютное позиционирование */
  background: #fff; /* Цвет фона */
  width: 450px; /* Ширина */
  padding: 20px; /* Поля */
  min-height: 100px; /* Минимальная высота */
  left: 0;
  right: 0; /* Нужно для выравнивания */
  margin: 80px auto 0;
}

.ModalLayout_title__toSC8 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 30px;
}

.ModalLayout_crossIconContainer__LPsTV {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media only screen and (max-width: 950px) {
  .ModalLayout_modal__SK3jt {
    width: 82vw;
    margin: 0 auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/layouts/ModalLayout/ModalLayout.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAGhB;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,wBAAA;EACA,YAAA;AADF;;AAIA;EACE,kBAAA,EAAA,gCAAA;EACA,gBAAA,EAAA,cAAA;EACA,YAAA,EAAA,WAAA;EACA,aAAA,EAAA,SAAA;EACA,iBAAA,EAAA,uBAAA;EACA,OAAA;EACA,QAAA,EAAA,2BAAA;EACA,mBAAA;AADF;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AADF;;AAIA;EACE,kBAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AADF;;AAIA;EACE;IACE,WAAA;IACA,cAAA;EADF;AACF","sourcesContent":["@import 'src/common/styles/variables';\n@import 'src/common/styles/mixins';\n\n.container {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.8);\n  display: flex;\n  align-items: center;\n  justify-content: center§;\n  z-index: 999;\n}\n\n.modal {\n  position: absolute; /* Абсолютное позиционирование */\n  background: #fff; /* Цвет фона */\n  width: 450px; /* Ширина */\n  padding: 20px; /* Поля */\n  min-height: 100px; /* Минимальная высота */\n  left: 0;\n  right: 0; /* Нужно для выравнивания */\n  margin: 80px auto 0;\n}\n\n.title {\n  font-size: 20px;\n  font-weight: 600;\n  text-align: center;\n  margin-top: 30px;\n}\n\n.crossIconContainer {\n  position: absolute;\n  right: 0;\n  top: 0;\n  width: 50px;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n@media only screen and (max-width: 950px) {\n  .modal {\n    width: 82vw;\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ModalLayout_container__3SBgU`,
	"modal": `ModalLayout_modal__SK3jt`,
	"title": `ModalLayout_title__toSC8`,
	"crossIconContainer": `ModalLayout_crossIconContainer__LPsTV`
};
export default ___CSS_LOADER_EXPORT___;
