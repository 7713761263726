import React, { useEffect, useState } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Header, Loader, Sidebar } from 'components';
import { routes } from 'enums';
import styles from './PrivateLayout.module.scss';
import { useActions, useAppSelector } from 'hooks';
import { accessTokenSelect, authThunk, isLoadingSelect } from 'state';
import Burger from 'components/Burger/Burger';
import Menu from 'components/Menu/Menu';

const useOnClickOutside = (ref: any, handler: any) => {
  React.useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

export const PrivateLayout = () => {
  const location = useLocation();
  const token = useAppSelector(accessTokenSelect);
  const initialized = true;
  const { getMe } = useActions(authThunk);
  const isLoading = useAppSelector(isLoadingSelect);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen]: any = useState(false);

  const [open, setOpen] = React.useState(false);
  const node: any = React.useRef();
  useOnClickOutside(node, () => setOpen(false));

  useEffect(() => {
    getMe();
  }, []);
  console.log(isLoading);

  if (!initialized || isLoading) {
    return <Loader />;
  }

  const handleOpenBurgerMenu = () => {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  };

  return token ? (
    <div className={styles.privateLayout}>
      <Sidebar />

      {open && <div className={styles.shadowContainer}></div>}

      <div ref={node}>
        <div className={styles.burgerContainer}>
          <Burger open={open} setOpen={setOpen} />
        </div>

        <Menu open={open} />
      </div>

      {/* <div
        style={{ width: 200, height: 200, backgroundColor: 'red' }}
        onClick={handleOpenBurgerMenu}
      ></div>
      <Menu isOpen={false} /> */}

      <Header />

      <div className={styles.outletContainer}>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={routes.AUTH.LOG_IN} replace state={location} />
  );
};
