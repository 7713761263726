import React, { ReactNode, useState } from 'react';

import styles from './CustomNavItem.module.scss';
import { SidebarItems } from 'enums';
import { clsx } from 'clsx';

interface ICustomNavItemProps {
  title: string;
  icon: ReactNode;
  onSelect: () => void;
  selectedItem: SidebarItems;
}

export const CustomNavItem = ({
  title,
  icon,
  onSelect,
  selectedItem,
}: ICustomNavItemProps) => {
  return (
    <div className={styles.container} onClick={() => onSelect()}>
      {icon}
      <div
        className={clsx(
          selectedItem === title ? [styles.text, styles.active] : styles.text,
        )}
      >
        {title}
      </div>
      {selectedItem === title && <div className={styles.highlighting}></div>}
    </div>
  );
};
