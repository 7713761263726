// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_container__tRE6e {
  width: 256px;
  border-right: 1px solid #d8d8d8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.Sidebar_logo__Mc4Gd {
  width: 74px;
  height: 74px;
  margin: 0 auto;
  margin-top: 37px;
}

.Sidebar_buttonContainer__uvZx0 {
  width: 90%;
  margin-left: 5%;
  bottom: 20px;
  position: absolute;
}

.Sidebar_itemsContainer__kErpX {
  margin-top: 60px;
  position: relative;
}

@media only screen and (max-width: 1250px) {
  .Sidebar_container__tRE6e {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,+BAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;AAFF;;AAKA;EACE,UAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,kBAAA;AAFF;;AAKA;EACE;IACE,aAAA;EAFF;AACF","sourcesContent":["@import 'src/common/styles/variables';\n@import 'src/common/styles/mixins';\n\n.container {\n  width: 256px;\n  border-right: 1px solid $color1;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n}\n\n.logo {\n  width: 74px;\n  height: 74px;\n  margin: 0 auto;\n  margin-top: 37px;\n}\n\n.buttonContainer {\n  width: 90%;\n  margin-left: 5%;\n  bottom: 20px;\n  position: absolute;\n}\n\n.itemsContainer {\n  margin-top: 60px;\n  position: relative;\n}\n\n@media only screen and (max-width: 1250px) {\n  .container {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Sidebar_container__tRE6e`,
	"logo": `Sidebar_logo__Mc4Gd`,
	"buttonContainer": `Sidebar_buttonContainer__uvZx0`,
	"itemsContainer": `Sidebar_itemsContainer__kErpX`
};
export default ___CSS_LOADER_EXPORT___;
