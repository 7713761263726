import React, { useEffect, useRef, useState } from 'react';

import styles from './Header.module.scss';
import { Bell, BellDark, Profile, RedEllipse } from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import { LsKeys, routes } from 'enums';
import {
  adminNotificationsSelect,
  authActions,
  notificationSelect,
  notificationThunk,
  userSelect,
} from 'state';
import { useActions, useAppDispatch, useAppSelector } from 'hooks';
import BellGray from 'assets/icons/BellGray';
import { Menu, MenuItem } from '@mui/material';

const NotificationItem = ({ body, date }: any) => {
  return (
    <div className={styles.notificationItemContainer}>
      <div className={styles.notificationItemIcon}>
        <BellGray />
      </div>

      <div>
        <div className={styles.notificationItemBody}>{body}</div>
        <div className={styles.notificationItemDate}>{date}</div>
      </div>
    </div>
  );
};

export const Header = () => {
  const navigate = useNavigate();

  const adminNotifications: any = useAppSelector(adminNotificationsSelect);
  const modalRef: any = useRef();
  const logoutRef: any = useRef(null);
  const dispatch = useAppDispatch();
  const [isOpenNotification, setOpenNotification] = useState(false);
  const [isOpenLogout, setOpenLogout] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { getNotificationsForAdmin } = useActions(notificationThunk);
  const user: any = useAppSelector(userSelect);

  const handleProfileClick = (event: any) => {
    setOpenLogout(!isOpenLogout);
    //setAnchorEl(event.currentTarget);
    return;
    localStorage.removeItem(LsKeys.TOKEN_INFO);
    dispatch(authActions.setLogOut());
    navigate(routes.AUTH.LOG_IN);
  };

  useEffect(() => {
    console.log('change anchorEl');
    console.log(anchorEl);
  }, [anchorEl]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(authActions.setLogOut());
    localStorage.removeItem(LsKeys.TOKEN_INFO);
    navigate(routes.AUTH.LOG_IN);
    handleMenuClose();
  };

  useEffect(() => {
    console.log('adminNotifications --->');
    console.log(adminNotifications);
  }, [adminNotifications]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenNotification(false);
      }
      if (logoutRef.current && !logoutRef.current.contains(event.target)) {
        setOpenLogout(false);
      }
    };

    if (isOpenNotification) {
      getNotificationsForAdmin(user.id);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenNotification, isOpenLogout, getNotificationsForAdmin, user.id]);

  const convertDate = (timestamp: any) => {
    const date = new Date(timestamp);

    const options: any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    const readableDate = date.toLocaleString('en-US', options);
    return readableDate;
  };

  return (
    <div className={styles.container} ref={modalRef}>
      <div className={styles.icon}>
        <div
          onClick={() => {
            setOpenNotification(!isOpenNotification);
          }}
        >
          {isOpenNotification ? <BellDark /> : <Bell />}
          {/* <div className={styles.redEllipse}>
            <RedEllipse />
          </div> */}
        </div>

        {isOpenNotification && (
          <div className={styles.notificationModalContainer}>
            {adminNotifications?.map((notif: any) => (
              <NotificationItem
                body={notif.contentEnglish}
                date={convertDate(notif.createdAt)}
              />
            ))}
          </div>
        )}
      </div>

      <div onClick={handleProfileClick} className={styles.icon}>
        <Profile />
        {isOpenLogout && (
          <div
            onClick={handleLogout}
            className={styles.logoutModalContainer}
            ref={logoutRef}
          >
            Log out
          </div>
        )}
        {/* <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu> */}
      </div>
    </div>
  );
};
