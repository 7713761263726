import React, { Suspense } from 'react';

import { Router } from './router';

import { Loader } from 'components/Loader';

export const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Loader />
      <Router />
    </Suspense>
  );
};
