import { ComponentPropsWithoutRef, ElementType } from 'react';

import { clsx } from 'clsx';

import './Typography.scss';

type TextProps<T extends ElementType> = {
  as?: T;
  variant?: 'h1' | 'h2' | 'h4' | 'p1' | 'p2' | 'p3' | 'btn1' | 'btn2' | 'link1';
  className?: string;
} & ComponentPropsWithoutRef<T>;

export const Typography = <T extends ElementType = 'p'>({
  as,
  className,
  variant = 'p1',
  ...rest
}: TextProps<T> & Omit<ComponentPropsWithoutRef<T>, keyof TextProps<T>>) => {
  const Component = as || 'p';

  return <Component className={clsx(variant, className)} {...rest} />;
};
