import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changePasswordRequest,
  createNotificationRequest,
  deleteNotificationRequest,
  getMeRequest,
  getNotificationRequest,
  getNotificationsForAdminRequest,
  getNotificationsRequest,
  logInRequest,
  LoginRequestType,
  restorePasswordRequest,
  signUpRequest,
  SignUpRequestType,
  updateNotificationeByIdRequest,
  verifyResetRequest,
} from 'api';
import { LsKeys, routes } from 'enums';
import { authActions } from 'state';

export const getNotifications = createAsyncThunk(
  'notifications/get',
  async ({ q, take, page }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getNotificationsRequest({ q, take, page });

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getNotification = createAsyncThunk(
  'notification/get',
  async (id: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getNotificationRequest(id);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const deleteNotification = createAsyncThunk(
  'notification/delete',
  async (id: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await deleteNotificationRequest(id);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getNotificationsForAdmin = createAsyncThunk(
  'notificationsForAdmin/get',
  async (id: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getNotificationsForAdminRequest(id);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const createNotification = createAsyncThunk(
  'notifications/create',
  async (data: any, { rejectWithValue, getState, dispatch }) => {
    try {
      console.log('notification thunk');
      const res = await createNotificationRequest(data);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      //thunkAPI.dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const updateNotification = createAsyncThunk(
  'notifications/update',
  async ({ data, id }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await updateNotificationeByIdRequest(id, data);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      //thunkAPI.dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const notificationThunk = {
  getNotifications,
  createNotification,
  getNotificationsForAdmin,
  getNotification,
  deleteNotification,
  updateNotification,
};
