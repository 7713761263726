// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_container__TsUVY {
  z-index: 1;
  width: calc(100vw - 250px);
  height: 60px;
  z-index: 9;
  position: absolute;
  right: 0;
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 25px;
}

.Header_icon__a8FkJ {
  cursor: pointer;
  margin-right: 18px;
}

.Header_redEllipse__MSrGd {
  position: absolute;
  top: 7px;
  padding-left: 18px;
}

.Header_notificationModalContainer__3Jv0x {
  width: 310px;
  right: 80px;
  height: 317px;
  background-color: white;
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  overflow: auto;
  padding: 10px 0px;
}

.Header_notificationItemContainer__4\\+8Sx {
  width: 100%;
  min-height: 62px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.Header_notificationItemIcon__NAtjW {
  padding: 10px 14px;
}

.Header_notificationItemBody__ikaB\\+ {
  font-weight: 600;
}

.Header_notificationItemDate__1pTNF {
  color: #b4b6c0;
  margin-top: 2px;
  font-size: 14px;
}

@media only screen and (max-width: 1250px) {
  .Header_container__TsUVY {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAGA;EACE,UAAA;EACA,0BAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;EACA,QAAA;EACA,gCAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAFF;;AAKA;EACE,eAAA;EACA,kBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,QAAA;EACA,kBAAA;AAFF;;AAKA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,uCAAA;EACA,cAAA;EACA,iBAAA;AAFF;;AAKA;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,cAAA;EACA,eAAA;EACA,eAAA;AAFF;;AAKA;EACE;IACE,YAAA;EAFF;AACF","sourcesContent":["@import 'src/common/styles/mixins';\n@import 'src/common/styles/variables';\n\n.container {\n  z-index: 1;\n  width: calc(100vw - 250px);\n  height: 60px;\n  z-index: 9;\n  position: absolute;\n  right: 0;\n  border-bottom: 1px solid $color1;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding-right: 25px;\n}\n\n.icon {\n  cursor: pointer;\n  margin-right: 18px;\n}\n\n.redEllipse {\n  position: absolute;\n  top: 7px;\n  padding-left: 18px;\n}\n\n.notificationModalContainer {\n  width: 310px;\n  right: 80px;\n  height: 317px;\n  background-color: white;\n  position: absolute;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);\n  overflow: auto;\n  padding: 10px 0px;\n}\n\n.notificationItemContainer {\n  width: 100%;\n  min-height: 62px;\n  display: flex;\n  align-items: center;\n  margin: 10px 0;\n}\n\n.notificationItemIcon {\n  padding: 10px 14px;\n}\n\n.notificationItemBody {\n  font-weight: 600;\n}\n\n.notificationItemDate {\n  color: #b4b6c0;\n  margin-top: 2px;\n  font-size: 14px;\n}\n\n@media only screen and (max-width: 1250px) {\n  .container {\n    width: 100vw;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Header_container__TsUVY`,
	"icon": `Header_icon__a8FkJ`,
	"redEllipse": `Header_redEllipse__MSrGd`,
	"notificationModalContainer": `Header_notificationModalContainer__3Jv0x`,
	"notificationItemContainer": `Header_notificationItemContainer__4+8Sx`,
	"notificationItemIcon": `Header_notificationItemIcon__NAtjW`,
	"notificationItemBody": `Header_notificationItemBody__ikaB+`,
	"notificationItemDate": `Header_notificationItemDate__1pTNF`
};
export default ___CSS_LOADER_EXPORT___;
