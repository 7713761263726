import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import styles from './Articles.module.scss';

import { routes } from 'enums';
import {
  LeftArrow,
  RightArrow,
  Search,
  Pencil,
  SaveIcon,
  DotsVertical,
} from 'assets/icons';
import { useActions, useAppSelector, useDebounce } from 'hooks';
import {
  articleThunk,
  doctorThunk,
  notificationThunk,
  notificationsSelect,
  userSelect,
} from 'state';
import { CustomButton } from 'components/CustomButton';
import { NotificationModal } from 'components/NotificationModal';
import clsx from 'clsx';
import { userThunk } from 'state/thunks/userThunk/userThunk';
import { usersSelect } from 'state/selectors/userSelectors';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { doctorsSelect } from 'state/selectors/doctorSelectors';
import { stat } from 'fs/promises';
import { CustomDropdown } from 'ui-kit/CustomDropdown';
import { financeThunk } from 'state/thunks/financeThunk/financeThunk';
import { financesSelect } from 'state/selectors/financeSelectors';
import { articleSelect, articlesSelect } from 'state/selectors/articleSelectors';
import { ArticleModal } from 'components/ArticleModal';
import { EmptyStub } from 'components/EmptyStub';
import Edit from 'assets/icons/Edit';
import Delete from 'assets/icons/Delete';

const useOnClickOutside = (ref: any, handler: any) => {
  React.useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    };
    document.addEventListener('mousedown', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const ArticleItem = ({
  ref,
  article,
  columns,
  handleGetOne,
  openItem,
  setOpenItem,
  deleteArticle,
}: any) => {
  const convertDate = (date: any) => {
    let now: any = new Date(date);
    return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
  };
  const itemsObj: any = {
    'Publishing Date': (
      <div className={styles.customCol1} style={{ width: '30%' }}>
        {convertDate(article.publishingDate)}
      </div>
    ),
    Content: (
      <div className={styles.customCol1} style={{ width: '70%' }}>
        {article.contentEnglish}
      </div>
    ),
  };

  const node: any = React.useRef();
  useOnClickOutside(node, () => setOpenItem(null));

  return (
    <div
      className={styles.tableItem}
      //onClick={() => handleGetOne(article.id)}
    >
      {columns.map((column: any) => {
        if (column.isSelect) {
          return itemsObj[column.name];
        }
      })}

      <div
        onClick={() => setOpenItem(article.id)}
        className={styles.eventButtonContainer}
      >
        <DotsVertical />
        {openItem === article.id && (
          <div ref={node} className={styles.eventContainer}>
            <div
              onClick={() => {
                setOpenItem(null);
                handleGetOne(article.id);
              }}
              className={styles.eventItemContainer}
            >
              <Edit />
              <div className={styles.eventItemText}>Edit</div>
            </div>
            <div
              onClick={() => {
                setOpenItem(null);
                deleteArticle(article.id);
              }}
              className={styles.eventItemContainer}
            >
              <Delete />
              <div className={styles.eventItemText}>Delete</div>
            </div>
          </div>
        )}
      </div>

      {/* <div style={{ width: '30%', textAlign: 'center' }}>
        {convertDate(article.publishingDate)}
      </div>
      <div style={{ width: '70%', textAlign: 'center' }}>{article.content}</div> */}
    </div>
  );
};

const FinanceItem = ({
  user,
  columns,
  columnWidth,
  handleEditClick,
  isEditable,
  handleChangePaymentStatus,
}: any) => {
  console.log(user);
  const itemsObj: any = {
    'Doctor name': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.name}
      </div>
    ),
    'Specialist Type': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.category}
      </div>
    ),
    Speciality: (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {user.user.doctor.speciality ? user.user.doctor.speciality : '-'}
      </div>
    ),
    'For month': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {months[user.month]}
      </div>
    ),
    Amount: (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        ${user.amount}
      </div>
    ),
    'Due date': (
      <div className={styles.customCol1} style={{ width: columnWidth + '%' }}>
        {'???'}
      </div>
    ),
    'Payment status': !isEditable ? (
      <div
        className={styles.customCol1}
        style={{ width: columnWidth + '%', fontWeight: 'bold' }}
      >
        {user.paymentStatus}
      </div>
    ) : (
      <div
        className={styles.customCol1}
        style={{ width: columnWidth + '%', fontWeight: 'bold' }}
      >
        <FormControl sx={{ m: 1, minWidth: 70 }}>
          <Select
            value={user.paymentStatus}
            onChange={e => {
              handleEditClick();
              handleChangePaymentStatus(user.id, {
                ...user,
                paymentStatus: e.target.value,
              });
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            style={{ height: 40, padding: 0 }}
          >
            <MenuItem value={'Not Paid'}>Not Paid</MenuItem>
            <MenuItem value={'Paid'}>Paid</MenuItem>
          </Select>
        </FormControl>
      </div>
    ),
    Status: (
      <div
        className={styles.customCol1}
        id="Status"
        style={
          user.user.doctor.status
            ? { color: 'green', fontWeight: 'bold' }
            : { color: 'red', fontWeight: 'bold' }
        }
      >
        {user.user.doctor.status ? 'Active' : 'Blocked'}
      </div>
    ),
  };

  return (
    <div className={styles.tableItem}>
      {columns.map((column: any) => {
        if (column.isSelect) {
          return itemsObj[column.name];
        }
      })}
      <div
        onClick={handleEditClick}
        className={styles.customCol1}
        style={{ width: columnWidth / 2 + '%', cursor: 'pointer' }}
      >
        {isEditable ? <SaveIcon /> : <Pencil />}
      </div>
    </div>
  );
};

const defaultColumns = [
  {
    name: 'Publishing Date',
    isSelect: true,
  },
  {
    name: 'Content',
    isSelect: true,
  },
];

export const Articles = () => {
  const navigate = useNavigate();
  const [isNotificationModal, setNotificationModal] = useState(false);
  const { getUsers } = useActions(userThunk);
  const { getDoctors } = useActions(doctorThunk);
  const { getArticles, getArticle, deleteArticle } = useActions(articleThunk);
  const users: any = useAppSelector(usersSelect);
  const doctors: any = useAppSelector(doctorsSelect);
  const finances: any = useAppSelector(financesSelect);
  const articles: any = useAppSelector(articlesSelect);
  const article: any = useAppSelector(articleSelect);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [bodyMessage, setBodyMessage] = useState('');
  const [isArticleModal, setArticleModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);
  const [columnWidth, setColumnWidth] = useState(100);
  const [selectedEditableItem, setSelectedEditableItem]: any = useState([]);

  const [openItem, setOpenItem] = useState(null);

  const handleColumnChange = (name: any) => {
    console.log(name);
    setColumns(
      columns.map(el => {
        if (el.name === name) {
          return {
            ...el,
            isSelect: !el.isSelect,
          };
        } else {
          return el;
        }
      }),
    );
  };

  // useEffect(() => {
  //   getColumnWidth();
  // }, [columns]);

  useEffect(() => {
    const columnCount = getColumnWidth();
    setColumnWidth(columnCount);
  }, [columns]);

  const getColumnWidth = () => {
    const columnCount = columns.reduce((accumulator: any, el: any) => {
      if (el.isSelect) {
        return accumulator + 1;
      }
      return accumulator;
    }, 0);

    console.log(columnCount);

    return 100 / columnCount;
  };

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const convertStatusValue = () => {
    switch (statusValue) {
      case 'active':
        return true;
        break;
      case 'blocked':
        return false;
        break;
      case '':
        return '';
    }
  };

  useEffect(() => {
    if (debouncedSearchValue) {
      setIsSearching(true);
      getArticles({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: '',
      });
    } else {
      getArticles({
        q: searchValue,
        take: itemPerPage,
        page: '',
      });
    }
  }, [debouncedSearchValue]);

  const itemPerPage = 20;

  useEffect(() => {
    getUsers({ q: '', take: itemPerPage, page: '' });
    getArticles({
      q: '',
      take: itemPerPage,
      page: '',
    });
  }, []);

  const handleLeftArrowClick = () => {
    if (articles?.page > 1) {
      getArticles({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: articles?.page - 1,
      });
    }
  };

  const handleRightArrowClick = () => {
    if (articles?.page * itemPerPage < articles.count) {
      getArticles({
        q: debouncedSearchValue,
        take: itemPerPage,
        page: articles?.page + 1,
      });
    }
  };

  const handleGetOne = (id: any) => {
    getArticle(id);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.flex}>
          <div className={styles.title}>All Users reminders</div>
          <div className={styles.pagination}>
            {articles?.page * itemPerPage + 1 - itemPerPage}-
            {articles?.page * itemPerPage} from {articles?.count}
          </div>
          <div className={styles.arrowContainer} onClick={handleLeftArrowClick}>
            <LeftArrow />
          </div>
          <div className={styles.arrowContainer} onClick={handleRightArrowClick}>
            <RightArrow />
          </div>
        </div>

        <div className={styles.flex}>
          <CustomDropdown columns={columns} handleColumnChange={handleColumnChange} />

          <div className={styles.inputContainerSearch}>
            <div className={styles.searchIconContainer}>
              <Search />
            </div>

            <input
              value={searchValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
              placeholder="Enter your request"
              className={styles.inputSearch}
            />
          </div>
          <div className={styles.buttonContainer}>
            <CustomButton
              dark
              title={'+Add new reminder'}
              handleClick={() => setArticleModal(true)}
            />
          </div>
        </div>
      </div>

      {isArticleModal && <ArticleModal setArticleModal={setArticleModal} />}
      {article && <ArticleModal isEdit setArticleModal={setArticleModal} />}

      <div className={styles.table}>
        <div className={styles.tableHeader}>
          {columns.map(el =>
            el.isSelect ? (
              <div
                className={clsx([styles.customCol1, styles.bold])}
                style={{
                  textAlign: 'center',
                  width: el.name == 'Content' ? '70%' : '30%',
                }}
              >
                {el.name}
              </div>
            ) : (
              <></>
            ),
          )}
        </div>

        {articles?.rows && articles?.rows[0] ? (
          articles?.rows?.map((article: any, index: any) => {
            return (
              <ArticleItem
                deleteArticle={deleteArticle}
                openItem={openItem}
                setOpenItem={setOpenItem}
                handleGetOne={handleGetOne}
                article={article}
                columns={columns}
              />
            );
          })
        ) : (
          <EmptyStub />
        )}
      </div>
    </div>
  );
};
