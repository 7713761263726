import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ModalLayout } from 'layouts/ModalLayout/ModalLayout';
import { useActions } from 'hooks';
import { articleThunk, notificationThunk } from 'state';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import styles from './WarningModal.module.scss';

interface IArticleModalProps {
  setArticleModal: (value: boolean) => void;
}

export const WarningModal = ({ setWarningModal, height, onConfirm }: any) => {
  const [bodyMessage, setBodyMessage] = useState('');
  const { createArticle } = useActions(articleThunk);
  const [value, onChange] = useState<any>(new Date());

  const handleAdd = () => {
    const data = {
      publishingDate: value,
      content: bodyMessage,
    };

    setBodyMessage('');
    createArticle(data);
    setWarningModal(false);
  };

  const handleClose = () => {
    setWarningModal(false);
    setBodyMessage('');
  };

  return (
    <ModalLayout
      height={height}
      handleClose={handleClose}
      title={'Updates will not be saved'}
    >
      <div className={styles.buttonDark} onClick={onConfirm}>
        Ok
      </div>
    </ModalLayout>
  );
};
