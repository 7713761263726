import { useState } from 'react';
import styles from './Sidebar.module.scss';
import logo from '../../assets/images/logo.png';
import {
  Dashboard,
  Finance,
  Reminder,
  Union,
  Users,
  Notification,
  Commission,
  Info,
} from 'assets/icons';
import { useNavigate } from 'react-router-dom';
import { SidebarItems, routes } from 'enums';
import { CustomNavItem } from 'components/CustomNavItem';
import { CustomButton } from 'components/CustomButton';
import { ArticleModal } from 'components/ArticleModal';
import { ContactUsModal } from 'components/ContactUsModal';

export const Sidebar = () => {
  const [isContactModal, setIsContactModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(SidebarItems.DASHBOARD);
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} />
      <div className={styles.itemsContainer}>
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.DASHBOARD);
            setSelectedItem(SidebarItems.DASHBOARD);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.DASHBOARD}
          icon={<Dashboard active={selectedItem === SidebarItems.DASHBOARD} />}
        />
        <CustomNavItem
          onSelect={() => {
            setSelectedItem(SidebarItems.USERS);
            navigate(routes.MAIN.USERS);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.USERS}
          icon={<Users active={selectedItem === SidebarItems.USERS} />}
        />
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.DOCTORS);
            setSelectedItem(SidebarItems.DOCTORS_NURSES);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.DOCTORS_NURSES}
          icon={<Union active={selectedItem === SidebarItems.DOCTORS_NURSES} />}
        />
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.FINANCES);
            setSelectedItem(SidebarItems.FINANCE);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.FINANCE}
          icon={<Finance active={selectedItem === SidebarItems.FINANCE} />}
        />
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.ARTICLES);
            setSelectedItem(SidebarItems.DAILY_USERS_REMINDER);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.DAILY_USERS_REMINDER}
          icon={<Reminder active={selectedItem === SidebarItems.DAILY_USERS_REMINDER} />}
        />
        <CustomNavItem
          onSelect={() => {
            setSelectedItem(SidebarItems.URGENT_NOTIFICATION);
            navigate(routes.MAIN.NOTIFICATION);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.URGENT_NOTIFICATION}
          icon={
            <Notification active={selectedItem === SidebarItems.URGENT_NOTIFICATION} />
          }
        />
        <CustomNavItem
          onSelect={() => {
            navigate(routes.MAIN.COMMISSION);
            setSelectedItem(SidebarItems.COMMISSION_RATES_PRICING);
          }}
          selectedItem={selectedItem}
          title={SidebarItems.COMMISSION_RATES_PRICING}
          icon={
            <Commission active={selectedItem === SidebarItems.COMMISSION_RATES_PRICING} />
          }
        />
      </div>

      {isContactModal && <ContactUsModal setContactModal={setIsContactModal} />}

      <div className={styles.buttonContainer}>
        <CustomButton
          isIcon
          title={'Contact us info'}
          handleClick={() => {
            setIsContactModal(true);
          }}
        />
      </div>
    </div>
  );
};
