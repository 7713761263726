import React, { ReactChild, ReactFragment, ReactPortal } from 'react';

import styles from './AuthFormLayout.module.scss';

import logo from 'assets/images/logo.png';
import { CustomButton } from 'components/CustomButton';
import { LeftArrow } from 'assets';
import { routes } from 'enums';
import { useNavigate } from 'react-router-dom';

type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined;

interface IAuthFormLayoutProps {
  children: ReactNode;
  onSubmit: () => void;
  handleResendResetClick?: () => void;
  title: string;
  subtitle?: string;
  bottomText?: string;
  isArrow?: boolean;
}

export const AuthFormLayout = ({
  children,
  onSubmit,
  title,
  subtitle,
  bottomText,
  handleResendResetClick,
  isArrow,
}: IAuthFormLayoutProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} />
      <p className={styles.title}>
        {isArrow && (
          <div
            className="reset-password-arrow"
            onClick={() => navigate(routes.AUTH.LOG_IN)}
          >
            <LeftArrow />
          </div>
        )}

        {title}
      </p>
      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      {children}
      <div className={styles.buttonContainer}>
        <CustomButton title={'Submit'} handleClick={onSubmit} />
      </div>

      {bottomText && (
        <p onClick={handleResendResetClick} className={styles.customLink}>
          {bottomText}
        </p>
      )}
    </div>
  );
};
