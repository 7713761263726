import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M7.50008 4.1665H5.83341C4.91294 4.1665 4.16675 4.9127 4.16675 5.83317V15.8332C4.16675 16.7536 4.91294 17.4998 5.83341 17.4998H14.1667C15.0872 17.4998 15.8334 16.7536 15.8334 15.8332V5.83317C15.8334 4.9127 15.0872 4.1665 14.1667 4.1665H12.5001"
        stroke={props.active ? 'black' : '#808080'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="7.5"
        y="2.5"
        width="5"
        height="3.33333"
        rx="1.66667"
        stroke={props.active ? 'black' : '#808080'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6666 9.1665H9.58325C8.8929 9.1665 8.33325 9.72615 8.33325 10.4165C8.33325 11.1069 8.8929 11.6665 9.58325 11.6665H10.4166C11.1069 11.6665 11.6666 12.2261 11.6666 12.9165C11.6666 13.6069 11.1069 14.1665 10.4166 14.1665H8.33325"
        stroke={props.active ? 'black' : '#808080'}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5999 14.1668C10.5999 13.8355 10.3313 13.5668 9.99992 13.5668C9.66855 13.5668 9.39992 13.8355 9.39992 14.1668H10.5999ZM9.39992 15.0002C9.39992 15.3315 9.66855 15.6002 9.99992 15.6002C10.3313 15.6002 10.5999 15.3315 10.5999 15.0002H9.39992ZM10.5999 8.3335C10.5999 8.00213 10.3313 7.7335 9.99992 7.7335C9.66855 7.7335 9.39992 8.00213 9.39992 8.3335H10.5999ZM9.39992 9.16683C9.39992 9.4982 9.66855 9.76683 9.99992 9.76683C10.3313 9.76683 10.5999 9.4982 10.5999 9.16683H9.39992ZM9.39992 14.1668V15.0002H10.5999V14.1668H9.39992ZM9.39992 8.3335V9.16683H10.5999V8.3335H9.39992Z"
        fill={props.active ? 'black' : '#808080'}
      />
    </g>
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
