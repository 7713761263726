import React, { useEffect, useState } from 'react';

import styles from './ChangePassword.module.scss';

import { AuthFormLayout } from 'layouts/AuthFormLayout/AuthFormLayout';
import { useNavigate } from 'react-router-dom';
import { routes } from 'enums';
import { CustomInput } from 'components/CustomInput';
import { useActions, useAppDispatch, useAppSelector } from 'hooks';
import {
  accessTokenSelect,
  authActions,
  authThunk,
  errorSelect,
  resetTokenSelect,
} from 'state';

export const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const navigate = useNavigate();
  //const [error, setError] = useState('');
  const { login } = useActions(authThunk);
  const token = useAppSelector(accessTokenSelect);
  const error = useAppSelector(errorSelect);
  const dispatch = useAppDispatch();
  const { changePassword } = useActions(authThunk);
  const resetToken: string = useAppSelector(resetTokenSelect);

  const handleSubmit = async () => {
    const changeData = {
      password,
      newPassword,
      token: resetToken,
    };
    await changePassword({ changeData, navigate });
    // const user = { phone: phone.trim(), password: password.trim() };
    // const res = await login({ user });
    // if (checkIsGoodStatus(res?.payload?.status)) {
    //   navigate(routes.MAIN.INIT);
    // }
    // if (email === 'admin@gmail.com' && password === '123456') {
    //   navigate(routes.MAIN.INIT);
    // } else {
    //   setError('Wrong credentials');
    // }
  };

  useEffect(() => {
    if (token) {
      navigate(routes.MAIN.INIT);
    }
  }, [token]);

  return (
    <AuthFormLayout
      title={'Change password'}
      subtitle={'To change the current password, enter the new password in both fields'}
      onSubmit={handleSubmit}
    >
      <div style={{ width: '70%', margin: '0 auto' }}>
        <CustomInput
          error={error}
          clearError={() => dispatch(authActions.clearError())}
          title={'Password'}
          value={password}
          onChange={setPassword}
          password
        />
      </div>

      <div style={{ width: '70%', margin: '0 auto' }}>
        <CustomInput
          error={error}
          clearError={() => dispatch(authActions.clearError())}
          title={'New password'}
          value={newPassword}
          onChange={setNewPassword}
          password
        />
      </div>
      <div
        style={{
          height: 26,
          fontSize: 15,
          color: 'red',
          //bottom: 20,
          margin: '0 auto',
          //marginTop: 5,
        }}
      >
        {error}
      </div>
    </AuthFormLayout>
  );
};
