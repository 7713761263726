import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LsKeys } from 'enums';
import {
  createNotification,
  deleteNotification,
  getNotification,
  getNotifications,
  getNotificationsForAdmin,
  updateNotification,
} from 'state/thunks/notificationThunk/notificationThunk';

const initialState = {
  notifications: null,
  notification: null,
  count: null,
  lastPage: null,
  page: null,
  notificationAdmin: null,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setLogOut: () => initialState,
    clearNotification: state => {
      state.notification = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder
      .addCase(getNotifications.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get notification success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.notifications = action.payload;
        state.count = action.payload.count;
        state.lastPage = action.payload.lastPage;
        state.page = action.payload.page;
      })
      .addCase(deleteNotification.fulfilled, (state, action: PayloadAction<any>) => {
        state.notifications.rows = state.notifications.rows.filter(
          (el: any) => el.id !== action.payload,
        );
      })
      .addCase(
        getNotificationsForAdmin.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.notificationAdmin = action.payload;
        },
      )
      .addCase(getNotification.fulfilled, (state, action: PayloadAction<any>) => {
        state.notification = action.payload;
      })
      .addCase(updateNotification.fulfilled, (state, action: PayloadAction<any>) => {
        state.notifications.rows = state.notifications.rows.map((el: any) =>
          el.id === action.payload.id ? action.payload : el,
        );
      })
      .addCase(createNotification.fulfilled, (state, action: PayloadAction<any>) => {
        console.log('get notification success');
        console.log(action.payload);
        console.log(action.payload.rows);
        state.notifications = action.payload;
        state.count = action.payload.count;
        state.lastPage = action.payload.lastPage;
        state.page = action.payload.page;
      })
      .addCase(getNotifications.pending, (state, action: PayloadAction<any>) => {
        // state.isLoading = true;
      })
      .addCase(getNotifications.rejected, (state, action: PayloadAction<any>) => {
        //state.error = action.payload;
        //state.isLoading = false;
      });
  },
});

export const notificationActions = slice.actions;

export const notificationReducer = slice.reducer;
