import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_463_6509)">
      <g clip-path="url(#clip0_463_6509)">
        <rect
          x="4.28027"
          y="3.48022"
          width="22.9605"
          height="22.9605"
          rx="11.4803"
          fill="white"
        />
        <g clip-path="url(#clip1_463_6509)">
          <path d="M14.1208 19.8808V16.1907H17.4009V19.8808H14.1208Z" fill="#FED2A5" />
          <path
            opacity="0.3"
            d="M15.7606 19.0606C14.5306 19.0606 13.5739 18.1039 13.3005 17.8306L17.8106 18.2406C17.4006 18.5139 16.5806 19.0606 15.7606 19.0606Z"
            fill="black"
          />
          <g filter="url(#filter1_d_463_6509)">
            <ellipse cx="15.7607" cy="14.1407" rx="4.92011" ry="4.5101" fill="#FED2A5" />
          </g>
          <path
            d="M23.9607 27.2609C23.9607 26.2379 23.7486 25.2249 23.3365 24.2797C22.9244 23.3346 22.3203 22.4758 21.5589 21.7524C20.7974 21.029 19.8934 20.4552 18.8986 20.0637C17.9037 19.6722 16.8373 19.4707 15.7605 19.4707C14.6836 19.4707 13.6173 19.6722 12.6224 20.0637C11.6275 20.4552 10.7235 21.029 9.96208 21.7524C9.20062 22.4758 8.5966 23.3346 8.1845 24.2797C7.77241 25.2249 7.5603 26.2379 7.5603 27.2609L15.7605 27.2609H23.9607Z"
            fill="#5CCEC4"
          />
          <path
            d="M12.4802 14.1403C12.0164 14.6042 11.3869 15.097 10.8402 14.9603C10.2935 13.457 11.6602 9.22021 15.7603 9.22021C19.8604 9.22021 20.9538 12.7736 20.6804 14.5503C20.4071 14.5503 19.7784 14.4683 19.4504 14.1403C19.1224 13.8123 18.2204 12.9103 18.2204 12.5003H13.3003C13.1636 12.7736 12.8902 13.7303 12.4802 14.1403Z"
            fill="#4B3F4E"
          />
          <rect x="17.4001" y="23.1606" width="4.10009" height="1.64004" fill="#7CE4DB" />
          <rect
            x="10.8406"
            y="14.9607"
            width="9.84022"
            height="0.820018"
            rx="0.410009"
            fill="white"
          />
          <rect
            x="13.3005"
            y="14.9607"
            width="4.92011"
            height="3.28007"
            rx="0.820018"
            fill="white"
          />
          <rect
            opacity="0.5"
            x="13.3005"
            y="15.7805"
            width="4.92011"
            height="0.820018"
            fill="#D9D9D9"
          />
          <path
            d="M19.0405 23.1606L18.2205 23.1606L18.2205 22.7506C18.2205 22.5242 18.4041 22.3406 18.6305 22.3406C18.857 22.3406 19.0405 22.5242 19.0405 22.7506L19.0405 23.1606Z"
            fill="#0149AD"
          />
          <path
            d="M19.8601 23.1606L19.0401 23.1606L19.0401 21.9306C19.0401 21.7042 19.2237 21.5206 19.4501 21.5206C19.6765 21.5206 19.8601 21.7042 19.8601 21.9306L19.8601 23.1606Z"
            fill="#E4E4E4"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_463_6509"
        x="0.453522"
        y="0.610161"
        width="30.614"
        height="30.614"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.956688" />
        <feGaussianBlur stdDeviation="1.91338" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_463_6509"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_463_6509"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_463_6509"
        x="10.8406"
        y="9.63062"
        width="9.84033"
        height="9.02026"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_463_6509"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_463_6509"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_463_6509">
        <rect
          x="4.28027"
          y="3.48022"
          width="22.9605"
          height="22.9605"
          rx="11.4803"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_463_6509">
        <rect
          width="16.4004"
          height="23.7805"
          fill="white"
          transform="translate(7.5603 4.30005)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
