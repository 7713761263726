import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_463_6461)">
      <g clip-path="url(#clip0_463_6461)">
        <rect
          x="4.34497"
          y="3.66016"
          width="22.9605"
          height="22.9605"
          rx="11.4803"
          fill="white"
        />
        <g clip-path="url(#clip1_463_6461)">
          <path d="M14.1857 21.7006V18.0105H17.4657V21.7006H14.1857Z" fill="#FED2A5" />
          <path
            opacity="0.3"
            d="M15.8254 20.8804C14.5954 20.8804 13.6387 19.9237 13.3654 19.6504L17.8755 20.0604C17.4654 20.3337 16.6454 20.8804 15.8254 20.8804Z"
            fill="black"
          />
          <g filter="url(#filter1_d_463_6461)">
            <ellipse cx="15.8255" cy="15.9605" rx="4.92011" ry="4.5101" fill="#FED2A5" />
          </g>
          <path
            d="M24.0256 29.0809C24.0256 28.0579 23.8135 27.0449 23.4014 26.0998C22.9893 25.1546 22.3853 24.2958 21.6238 23.5725C20.8624 22.8491 19.9584 22.2753 18.9635 21.8838C17.9686 21.4923 16.9023 21.2908 15.8254 21.2908C14.7486 21.2908 13.6822 21.4923 12.6874 21.8838C11.6925 22.2753 10.7885 22.8491 10.027 23.5725C9.26556 24.2958 8.66154 25.1546 8.24945 26.0998C7.83735 27.0449 7.62524 28.0579 7.62524 29.0809L15.8254 29.0809H24.0256Z"
            fill="#5CCEC4"
          />
        </g>
        <path
          d="M12.6594 15.9606C12.1956 16.4245 11.5661 16.9173 11.0194 16.7807C10.4727 15.2773 11.8394 11.0405 15.9395 11.0405C20.0396 11.0405 21.133 14.5939 20.8596 16.3706C20.5863 16.3706 19.9576 16.2886 19.6296 15.9606C19.3016 15.6326 18.3996 14.7306 18.3996 14.3206H13.4795C13.3428 14.5939 13.0694 15.5506 12.6594 15.9606Z"
          fill="#4B3F4E"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_463_6461"
        x="0.518219"
        y="0.790093"
        width="30.614"
        height="30.614"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.956688" />
        <feGaussianBlur stdDeviation="1.91338" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_463_6461"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_463_6461"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_463_6461"
        x="10.9054"
        y="11.4504"
        width="9.84021"
        height="9.02026"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_463_6461"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_463_6461"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_463_6461">
        <rect
          x="4.34497"
          y="3.66016"
          width="22.9605"
          height="22.9605"
          rx="11.4803"
          fill="white"
        />
      </clipPath>
      <clipPath id="clip1_463_6461">
        <rect
          width="16.4004"
          height="23.7805"
          fill="white"
          transform="translate(7.62512 6.12036)"
        />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
