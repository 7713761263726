import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LsKeys } from 'enums';
import { createArticle, getArticles } from 'state/thunks/articleThunk/articleThunk';
import { getContact, updateContact } from 'state/thunks/contactUsThunk/contactUsThunk';

const initialState = {
  doctors: null,
  doctor: null,
  count: null,
  lastPage: null,
  page: null,
  user: null,
  isLoading: false,
  visits: [],
  articles: [],
  contactInfo: null,
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setLogOut: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder
      .addCase(getContact.fulfilled, (state, action: PayloadAction<any>) => {
        console.log(action);
        state.contactInfo = action.payload;
      })
      .addCase(updateContact.fulfilled, (state, action: PayloadAction<any>) => {
        state.contactInfo = action.payload;
      });
  },
});

export const contactUsActions = slice.actions;

export const contactUsReducer = slice.reducer;
