// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WarningModal_custom-input__3dyui {
  outline: none;
  width: 90%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
}

.WarningModal_input-container__JFYI7 {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.WarningModal_custom-input-container__r9YGU {
  width: 70%;
  border: 1px solid #d7d7d7;
  margin: 0 auto;
  border-radius: 10px;
  height: 34px;
  display: flex;
  padding: 2px;
}

.WarningModal_input-title__PENqC {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 3px;
}

.WarningModal_buttonDark__4mY4K {
  width: 175px;
  height: 38px;
  border-radius: 10px;
  background-color: #0149ad;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 10px;
}

.WarningModal_notificationModalTextarea__LA\\+rD {
  border: 1px solid #d8d8d8;
  overflow: auto;
  outline: none;
  border-radius: 5px;
  padding: 10px;
  box-shadow: none;
  resize: none;
  width: calc(100% - 20px) !important;
  height: 70px !important;
}

.WarningModal_custom-input-icon-container__AbeFB {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/WarningModal/WarningModal.module.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,UAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,YAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAFF;;AAKA;EACE,UAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AAFF;;AAKA;EACE,UAAA;EACA,cAAA;EACA,kBAAA;AAFF;;AAKA;EACE,YAAA;EACA,YAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AAFF;;AAKA;EACE,yBAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EAIA,gBAAA;EACA,YAAA;EACA,mCAAA;EACA,uBAAA;AAHF;;AAMA;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AAHF","sourcesContent":["@import 'src/common/styles/mixins';\n@import 'src/common/styles/variables';\n\n.custom-input {\n  outline: none;\n  width: 90%;\n  margin: 0 auto;\n  padding-left: 15px;\n  padding-right: 15px;\n  border: none;\n}\n\n.input-container {\n  display: flex;\n  flex-direction: column;\n  margin-top: 10px;\n}\n\n.custom-input-container {\n  width: 70%;\n  border: 1px solid #d7d7d7;\n  margin: 0 auto;\n  border-radius: 10px;\n  height: 34px;\n  display: flex;\n  padding: 2px;\n}\n\n.input-title {\n  width: 70%;\n  margin: 0 auto;\n  margin-bottom: 3px;\n}\n\n.buttonDark {\n  width: 175px;\n  height: 38px;\n  border-radius: 10px;\n  background-color: #0149ad;\n  display: flex;\n  color: white;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  margin: 0 auto;\n  margin-top: 10px;\n}\n\n.notificationModalTextarea {\n  border: 1px solid #d8d8d8;\n  overflow: auto;\n  outline: none;\n  border-radius: 5px;\n  padding: 10px;\n\n  -webkit-box-shadow: none;\n  -moz-box-shadow: none;\n  box-shadow: none;\n  resize: none;\n  width: calc(100% - 20px) !important;\n  height: 70px !important;\n}\n\n.custom-input-icon-container {\n  width: 10%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-input": `WarningModal_custom-input__3dyui`,
	"input-container": `WarningModal_input-container__JFYI7`,
	"custom-input-container": `WarningModal_custom-input-container__r9YGU`,
	"input-title": `WarningModal_input-title__PENqC`,
	"buttonDark": `WarningModal_buttonDark__4mY4K`,
	"notificationModalTextarea": `WarningModal_notificationModalTextarea__LA+rD`,
	"custom-input-icon-container": `WarningModal_custom-input-icon-container__AbeFB`
};
export default ___CSS_LOADER_EXPORT___;
