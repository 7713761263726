import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changePasswordRequest,
  createArticleRequest,
  createNotificationRequest,
  deleteArticleByIdRequest,
  editFinanceRequest,
  editUserRequest,
  getArticleByIdRequest,
  getArticleRequest,
  getDoctorFinancesRequest,
  getDoctorsRequest,
  getDoctorVisitsRequest,
  getFinancesRequest,
  getMeRequest,
  getNotificationRequest,
  getPatientVisitsRequest,
  getUserRequest,
  getUsersRequest,
  logInRequest,
  LoginRequestType,
  restorePasswordRequest,
  signUpRequest,
  SignUpRequestType,
  updateArticleByIdRequest,
  verifyResetRequest,
} from 'api';

export const getArticles = createAsyncThunk(
  'article/get',
  async (query: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getArticleRequest(query);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const getArticle = createAsyncThunk(
  'article/getOne',
  async (id: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getArticleByIdRequest(id);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const deleteArticle = createAsyncThunk(
  'article/delete',
  async (id: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await deleteArticleByIdRequest(id);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const updateArticle = createAsyncThunk(
  'article/updateOne',
  async ({ data, id }: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const currentState = await getState();
      const res = await updateArticleByIdRequest(id, data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
    }
  },
);

export const createArticle = createAsyncThunk(
  'article/create',
  async (data: any, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await createArticleRequest(data);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
    }
  },
);

export const articleThunk = {
  getArticles,
  createArticle,
  updateArticle,
  getArticle,
  deleteArticle,
};
