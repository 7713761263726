import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changePasswordRequest,
  getMeRequest,
  logInRequest,
  LoginRequestType,
  restorePasswordRequest,
  signUpRequest,
  SignUpRequestType,
  verifyResetRequest,
} from 'api';
import { LsKeys, routes } from 'enums';
import { authActions } from 'state';

export const login = createAsyncThunk(
  'auth/login',
  async (
    { user }: { user: LoginRequestType },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const res = await logInRequest(user);
      console.log(res.data);
      console.log('----check role-------->');

      console.log('login success');
      return res.data;
    } catch (error: any) {
      console.log('login failed');
      console.log(error);
      return rejectWithValue(error.response.data.message);
    } finally {
      //thunkAPI.dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const getMe = createAsyncThunk(
  'auth/me',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await getMeRequest();
      console.log('navigate');
      // navigation.navigate(SCREENS.PROFILE);
      return res.data;
    } catch (error: any) {
      console.log('error get me');
      console.log(error);
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
      //SplashScreen.hide();
      //SplashScreen.hide();

      // thunkAPI.dispatch(authActions.setInitialized());
      // thunkAPI.dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const restorePassword = createAsyncThunk(
  'auth/restore-password',
  async (
    { restoreData }: { restoreData: any },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const res = await restorePasswordRequest(restoreData);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      //thunkAPI.dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const verifyReset = createAsyncThunk(
  'auth/verify-reset',
  async (
    { verifyData, navigate }: { verifyData: any; navigate: any },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      console.log('verify reset');
      const res = await verifyResetRequest(verifyData);
      navigate(routes.AUTH.CHANGE_PASSWORD);

      return res.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    } finally {
      console.log('finally');
      //thunkAPI.dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const changePassword = createAsyncThunk(
  'auth/change-password',
  async (
    { changeData, navigate }: { changeData: any; navigate: any },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const res = await changePasswordRequest(changeData);
      navigate(routes.AUTH.LOG_IN);

      return res.data;
    } catch (error) {
      console.log('---error change password');
      console.log(error);
    } finally {
      //thunkAPI.dispatch(authActions.setLoading({ isLoading: false }));
    }
  },
);

export const authThunk = { login, getMe, restorePassword, verifyReset, changePassword };
