import { SVGProps, memo } from 'react';

const SvgComponent = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2.5"
      y="3.8335"
      width="15"
      height="10"
      rx="1"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83325 17.1667H14.1666"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.49992 13.8335V17.1668"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4999 13.8335V17.1668"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.49992 10.4998V7.1665"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99992 10.4998V9.6665"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4999 10.5002V8.8335"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99992 10.4998V9.6665"
      stroke={props.active ? '#3C3C60' : '#808080'}
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const Memo = memo(SvgComponent);

export default Memo;
