// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomNavItem_container__GBVAV {
  display: flex;
  align-items: center;
  height: 38px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 20px;
  cursor: pointer;
}

.CustomNavItem_text__ofqHe {
  padding-left: 20px;
  color: #808080;
}

.CustomNavItem_active__8L5sN {
  color: #3c3c60;
}

.CustomNavItem_highlighting__mOgIt {
  width: 4px;
  height: 38px;
  border-radius: 10px;
  background-color: #00b8ea;
  position: absolute;
  right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/CustomNavItem/CustomNavItem.module.scss","webpack://./src/common/styles/variables.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,UAAA;EACA,cAAA;EACA,mBAAA;EACA,eAAA;AAFF;;AAKA;EACE,kBAAA;EACA,cCbO;ADWT;;AAKA;EACE,cClBO;ADgBT;;AAKA;EACE,UAAA;EACA,YAAA;EACA,mBAAA;EACA,yBCvBO;EDwBP,kBAAA;EACA,UAAA;AAFF","sourcesContent":["@import 'src/common/styles/mixins';\n@import 'src/common/styles/variables';\n\n.container {\n  display: flex;\n  align-items: center;\n  height: 38px;\n  width: 70%;\n  margin: 0 auto;\n  margin-bottom: 20px;\n  cursor: pointer;\n}\n\n.text {\n  padding-left: 20px;\n  color: $color3;\n}\n\n.active {\n  color: $color2;\n}\n\n.highlighting {\n  width: 4px;\n  height: 38px;\n  border-radius: 10px;\n  background-color: $color4;\n  position: absolute;\n  right: 6px;\n}\n","$color1: #d8d8d8;\n$color2: #3c3c60;\n$color3: #808080;\n$color4: #00b8ea;\n$color5: #ffffff;\n$color6: #bebebe;\n$color7: #0149ad;\n$color8: #dbe0e4;\n$color9: #c5c4cf;\n\n$textColor-l1: #0a142f;\n$textColor-l2: #666;\n$textColor-l3: #040404;\n\n$backgroundColor-l1: #ffffff;\n$backgroundColor-l2: #111;\n$backgroundColor-l3: #d2d2d2;\n\n$lineColor-l1: #9fb4cd;\n$lineColor-l2: #333;\n\n$errorColor-l1: #ff4b55;\n$successColor: #34a853;\n$linkColor: #4f72ee;\n$iconColor: rgb(132, 138, 155);\n\n// font Family\n$font-l1: 'Poppins';\n\n// font sizes\n$fontSize-xs: 0.875rem;\n$fontSize-s: 1rem;\n$fontSize-m: 1.25rem;\n$fontSize-l: 2.25rem;\n$fontSize-xl: 3.25rem;\n\n// line heights\n$lineHeight-s: 1rem;\n$lineHeight-m: 1.5rem;\n$lineHeight-l: 2rem;\n\n// font weights\n$fontWeight-s: 400;\n$fontWeight-m: 500;\n$fontWeight-l: 600;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `CustomNavItem_container__GBVAV`,
	"text": `CustomNavItem_text__ofqHe`,
	"active": `CustomNavItem_active__8L5sN`,
	"highlighting": `CustomNavItem_highlighting__mOgIt`
};
export default ___CSS_LOADER_EXPORT___;
