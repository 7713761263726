import { UserType } from 'api';
import { NotificationType } from 'common';
import { AppRootStateType } from 'state/store';

export const accessTokenSelect = (state: AppRootStateType) => state.auth.accessToken;
export const initializedSelect = (state: AppRootStateType) => state.auth.initialized;
export const isLoadingSelect = (state: AppRootStateType) => state.auth.isLoading;
export const userSelect = (state: AppRootStateType): UserType => state.auth.userInfo;
export const errorSelect = (state: AppRootStateType) => state.auth.error;
export const resetTokenSelect = (state: AppRootStateType) => state.auth.resetToken;
