import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { ModalLayout } from 'layouts/ModalLayout/ModalLayout';
import { useActions, useAppDispatch, useAppSelector } from 'hooks';
import { articleActions, articleThunk, notificationThunk } from 'state';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import styles from './ArticleModal.module.scss';
import { articleSelect } from 'state/selectors/articleSelectors';
import { Warning } from 'assets';

interface IArticleModalProps {
  setArticleModal: (value: boolean) => void;
  isEdit?: boolean;
}

export const ArticleModal = ({ setArticleModal, isEdit }: IArticleModalProps) => {
  const [bodyMessage, setBodyMessage] = useState('');
  const [bodyMessageArabic, setBodyMessageArabic] = useState('');
  const [isEnglish, setIsEnglish] = useState(true);
  const { createArticle, updateArticle } = useActions(articleThunk);
  const [value, onChange] = useState<any>(new Date());
  const article: any = useAppSelector(articleSelect);
  const dispatch = useAppDispatch();
  const [isWarning, setWarning] = useState(false);

  useEffect(() => {
    if (article) {
      setBodyMessage(article.contentEnglish);
      setBodyMessageArabic(article.contentArabic);
    }
  }, [article]);

  const handleAdd = () => {
    console.log('time');
    console.log(value);

    if (!bodyMessage || !bodyMessageArabic || !value) {
      setWarning(true);
      return;
    }

    const data = {
      publishingDate: value,
      contentEnglish: bodyMessage,
      contentArabic: bodyMessageArabic,
    };

    setBodyMessage('');
    setBodyMessageArabic('');
    isEdit ? updateArticle({ id: article.id, data }) : createArticle(data);
    setArticleModal(false);
    dispatch(articleActions.clearArticle());
  };

  const handleClose = () => {
    setArticleModal(false);
    setBodyMessage('');
    setBodyMessageArabic('');
    dispatch(articleActions.clearArticle());
  };

  const handleChangeLanguage = () => {
    setIsEnglish(!isEnglish);
  };

  return (
    <ModalLayout handleClose={handleClose} title={'Add new reminder'}>
      <div className={styles.radiobuttonContainer}>
        <div
          onClick={handleChangeLanguage}
          className={isEnglish ? styles.checkedLanguage : styles.uncheckedLanguage}
        >
          English
        </div>
        <div
          onClick={handleChangeLanguage}
          className={!isEnglish ? styles.checkedLanguage : styles.uncheckedLanguage}
        >
          Arabic
        </div>
      </div>

      <div>
        <p>Publishing date</p>
        <DatePicker
          minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
          onChange={onChange}
          value={value}
        />
      </div>
      <div>
        <p>Content</p>
        <textarea
          maxLength={100}
          className={styles.notificationModalTextarea}
          value={isEnglish ? bodyMessage : bodyMessageArabic}
          onChange={(e: any) => {
            isEnglish
              ? setBodyMessage(e.target.value)
              : setBodyMessageArabic(e.target.value);
          }}
        />
      </div>

      {isWarning && (
        <div className={styles.warningContainer}>
          <Warning />
          <div className={styles.warningText}>
            Both languages and publishing date ​​must be filled in
          </div>
        </div>
      )}

      <div className={styles.buttonDark} onClick={handleAdd}>
        {isEdit ? 'Save' : 'Add'}
      </div>
    </ModalLayout>
  );
};
